export default function RSVPNo() {
  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url('https://ocmedia-adezbcaabacpchh3.z01.azurefd.net/inviteNo.jpg')` }}
    >
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-10"></div>

      {/* Overlay Container */}
      <div className="relative z-10 bg-white/90 backdrop-blur-lg rounded-lg p-10 m-8 shadow-lg max-w-md text-center opacity-90">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Sorry, we’ll miss you!</h1>
        <p className="text-gray-600 text-lg mb-6">
          We're sorry to hear that you can't make it. We'll miss having you at the celebration!
        </p>
        <p className="text-gray-500">
          If you change your mind, feel free to reach out. We’d love to celebrate with you!
        </p>
        <div className="mt-8">
          <a
            href="/"
            className="inline-block bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 transition-colors duration-200"
          >
            Go Back to Homepage
          </a>
        </div>
      </div>
    </div>
  );
}
