// RSVPPage.tsx
import { MultiStepForm } from "@/components/MultiStepForm";

export default function RSVPPage() {
  return (



    <div
      className="relative min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url('https://ocmedia-adezbcaabacpchh3.z01.azurefd.net/squarepool.gif')` }}
    >
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-30"></div>

      {/* Main Container */}
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen w-full px-0">
        {/* Form Container */}
        <div className="w-full max-w-sm bg-white/50 backdrop-blur-lg px-6 py-6 rounded-lg shadow-lg">
          <MultiStepForm />
        </div>
      </div>
    </div>


    // <div className="min-h-screen flex items-center justify-center bg-gray-50">
    //   <div className="w-full max-w-sm bg-white/50 backdrop-blur-lg px-6 py-6 rounded-lg shadow-lg">
    //     <MultiStepForm />
    //   </div>
    // </div>
  );
}