import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MapPin, Clock, Shirt, ListTodo } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <div
      className="relative min-h-screen bg-cover bg-center text-white"
      style={{
        backgroundImage: "url('https://ocmedia-adezbcaabacpchh3.z01.azurefd.net/home.jpg')",
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-30"></div>

      <div className="relative container mx-auto px-4 py-20 flex flex-col items-center text-center">
        {/* Headline */}
        <h1 className="text-6xl font-bold mb-6">Welcome to Our Party!</h1>

        {/* Subtext */}
        <div className="text-2xl font-light max-w-3xl mb-4">
          Join us for fun in the sun, delicious food, and a day well spent with friends and family.
        </div>
        <p className="text-3xl font-semibold text-white mb-10">
          House Party + Pool Party + Friday + 4th of July = Best Day Ever!
        </p>

        {/* RSVP Button */}
        <Link to="/rsvp">
          <Button className="mb-12 bg-white text-black hover:bg-gray-200 text-lg font-semibold px-6 py-3">
            RSVP Now
          </Button>
        </Link>

        {/* Info Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-7xl text-left text-lg">
          {/* Location */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center text-2xl">
                <MapPin className="mr-2" /> Location
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>12471 Woodlawn Ave, Tustin, CA 92780</p>
            </CardContent>
          </Card>

          {/* Time */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center text-2xl">
                <Clock className="mr-2" /> Date & Time
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Friday, July 4th, 2025</p>
              <p className="text-base text-muted-foreground">
                Things kick off around 2 and we’ll be keeping it going all day long.
              </p>
            </CardContent>
          </Card>

          {/* Dress Code */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center text-2xl">
                <Shirt className="mr-2" /> Dress Code
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Casual Summer Attire</p>
              <p className="text-base text-muted-foreground">
                Think sandals, shades, and swimsuits. Bring layers for when the sun dips.
              </p>
            </CardContent>
          </Card>

          {/* Schedule */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center text-2xl">
                <ListTodo className="mr-2" /> Schedule
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>2:00 PM – Refreshments (libations, both spirited and sensible)</li>
                <li>3:00 PM – Taco Truck (pace yourself... or don’t)</li>
                <li>6:00 PM – Dinner & Music (dancing encouraged, shoes optional)</li>
              </ul>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
