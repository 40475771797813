// App.tsx
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "@/components/Layout";
import HomePage from "@/pages/HomePage";
import RSVPPage from "@/pages/RSVPPage";
import RSVPNo from "@/pages/RSVPNo";
import RSVPYes from "@/pages/RSVPYes";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/rsvp" element={<RSVPPage />} />
          <Route path="/sorry" element={<RSVPNo />} />
          <Route path="/thank-you" element={<RSVPYes />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
